<template>
  <v-card tile>
    <v-toolbar
      dark
      max-height="64"
      color="primary"
    >
      <v-toolbar-title>{{ $tc('vvtList.more.actions.bulk.title', records.length) }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="!$wait.is('pageLoader')"
        :disabled="!canSave || $wait.is('pageLoader')"
        color="success"
        @click="startBulk()"
      >{{ $t('vvtList.more.actions.bulk.start') }}</v-btn>
      <v-btn
        color="white"
        outlined
        fab
        small
        :elevation="2"
        class="ma-2"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-sheet v-for="field in selectedTargetFields" :key="field.fieldName + '_' + field.childFieldName" tile>
        <EditorField
          :field="field"
          :key="field.fieldName + '_' + field.childFieldName"
          v-model="field.value"
          :sameCompanyId="sameCompanyId"
          @modified="$emit('modified')"
        />
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import http from '@/utils/axios';
import EditorField from './EditorField';
export default {
    components: {
      EditorField
    },
    props: {
        canSave: {
          type: Boolean,
          default: false
        },
        sameCompanyId: {
          type: Number,
          default: null
        },
        records: {
          type: Array,
          default: () => []
        },
        selectedTargetFields: {
          type: Array,
          default: () => []
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    methods: {
      startBulk() {
          this.$wait.start('pageLoader');
          const data = {
              recordIds: this.records.map(x => x.id),
              fields: this.selectedTargetFields.map(x => {
                  return {
                      fieldName: x.fieldName,
                      childFieldName: x.childFieldName,
                      value: x.value
                  }
              })
          };
          http.post('/api/processingActivity/bulk', JSON.stringify(data)).then(() => {
              this.$emit('clearSelectedTargetFields');
              this.$emit('bulkDone');
              this.$wait.end('pageLoader');
          });
      }
    }
}
</script>